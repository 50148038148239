import { ITriageTabsFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import TriageTabs from './TriageTabs'
import { A11yTextRenderer } from 'src/siteBuilder/utils/text/TextRenderer'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'

const ContentfulTriageTabs = withContentful<ITriageTabsFields>(TriageTabs, {
  tabItems: (props) =>
    props.fields.tabItems
      .filter((tabItem) => tabItem.fields)
      .map((tab) => {
        const id = tab.fields.id.split(' ').join('-')
        return {
          id: id,
          label: <A11yTextRenderer>{tab.fields.label}</A11yTextRenderer>,
          content: <BlockRenderer block={tab.fields.content} />,
          icon: tab.fields?.icon,
          iconImageSrc: tab.fields.iconImage?.fields?.file.url,
        }
      }),
  variant: (props) => props.fields.variant,
  animated: (props) => props.fields.animated,
})

export default ContentfulTriageTabs
