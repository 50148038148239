import Palette from '@telus-uds/palette-allium/build/web/palette'
import styled from 'styled-components'

const TriageTabsContainer = styled.div`
  .triage-tabs-content {
    > div {
      visibility: hidden;
    }
    > .active {
      visibility: visible;
    }
    > .inactive {
      display: none;
    }
  }

  div[role='tablist'] > div {
    justify-content: center;

    > div:first-child {
      @media (min-width: 768px) {
        width: 100%;
        max-width: 968px;
      }
    }
  }

  // tab links
  a[role='tab'] {
    align-items: stretch;

    // equally split tabs' widths, with min/max values
    min-width: 120px;
    max-width: 200px !important;
    flex: 1 0 120px;
    @media (min-width: 768px) {
      min-width: 160px;
      max-width: none !important;
      flex: 1 0 160px;
    }

    > div {
      justify-content: flex-start;
    }

    // focus ring
    &:focus-visible > div {
      border-radius: 16px;
      outline: solid 2px ${Palette.color.purpleTelus} !important;
      outline-offset: -6px;

      // remove the default styling for this
      > div:first-child {
        border: 0 !important;
        background: transparent !important;
      }
    }
  }

  a[role='tab'][aria-selected='true'] {
    > div > div:nth-child(3) {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      background-image: linear-gradient(90deg, #4c276d, #a92d84);
    }
  }
`

export default TriageTabsContainer
